<template>
  <div>
    <div class="invoice-information">
      <div t-id="invoice-number">
        <telia-heading tag="h3" variant="subsection-100"
          ><strong>
            {{ t("mybusiness.invoice.number") }}
          </strong></telia-heading
        >
        <telia-p variant="paragraph-100">{{ invoiceNumber }}</telia-p>
      </div>
      <div t-id="invoice-account-number" class="billingAccountNumber">
        <telia-heading tag="h3" variant="subsection-100"
          ><strong>
            {{ t("mybusiness.billing.account") }}
          </strong></telia-heading
        >
        <telia-p variant="paragraph-100">{{ accountNumber }}</telia-p>
      </div>
    </div>
    <div t-id="download-pdf-description" class="description">
      <telia-p variant="paragraph-100">{{ t("downloadPdf.description ") }}</telia-p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { translateMixin } from "../../locale";

const t = translateMixin.methods.t;

defineProps({
  invoiceNumber: String,
  accountNumber: String,
});
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.invoice-information {
  display: grid;
  grid-template-columns: 1fr 1fr 1.2fr;
  padding: $telia-spacing-16;
  padding-left: 0;
}

.description {
  padding: $telia-spacing-16;
  padding-left: 0;
}

.billingAccountNumber {
  padding-left: $telia-spacing-12;
}
</style>
