<template>
  <div
    class="account-finder"
    id="account-search"
    t-id="invoice-filter-option-billing-account-number"
  >
    <b2x-input-suggestions
      t-id="b2x-input-suggestions"
      :label="t('mybusiness.billing.account')"
      :loading="loading"
      :disabled="disabled"
      :suggestions="accountsList"
      :error="accountsError"
      @inputValue="handleInputValue"
      @suggestionSelected="handleSuggestionSelected"
      :value="inputValue"
    ></b2x-input-suggestions>
  </div>
</template>

<script setup lang="ts">
import { logError } from "@telia/b2x-logging";
import { computed, ref } from "vue";
import useInvoiceListData from "../../../composables/invoice-list";
import { BillingAccount } from "../../../interfaces";
import { translateMixin } from "../../../locale";
import { searchAccounts } from "../../../services/corp-customer-billing-accounts";

const t = translateMixin.methods.t;
const { invoiceListData, layout, setAccountNrAndFetch, selectedOrganisation } = useInvoiceListData();

const inputValue = ref("");
const accounts = ref<BillingAccount[]>([]);
const loading = ref(false);
const error = ref(false);

const accountsList = computed(() => {
  const mappedAccounts = accounts.value
    .map((account) => {
      // Temporary solution, remove once m-number is completely gone in MYBD-16037
      return inputValue.value.toLowerCase().startsWith("m")
        ? account.number
        : account.accountNumber;
    })
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return JSON.stringify(mappedAccounts);
});

const accountsError = computed(() => {
  return error.value ? t("table.filterPanel.accountFilter.error") : "";
});

const disabled = computed(() => {
  return layout.value.showLoading;
});

const getAccountList = () => {
  inputValue.value.length > 2 ? setAccountList(inputValue.value) : clearAccountList();
};

const findAccount = (accountId: string) => {
  const accountToSelect = accounts.value.find((account) => account.number === accountId);
  if (accountToSelect) {
    selectAccount(accountToSelect.number, accountToSelect.accountNumber);
  } else {
    searchBDLx(accountId);
  }
};

const selectAccount = (billingAccountId: string, accountId: string) => {
  if (billingAccountId.length > 0) {
    accounts.value = [];
    setAccountNrAndFetch(inputValue.value, billingAccountId, accountId);
    clearInput();
  }
};
const clearAccountList = () => {
  accounts.value = [];
};

const setAccountList = (value: string) => {
  setAccounts(value);
};

const clearInput = () => {
  inputValue.value = "";
};

const searchBDLx = (accountId: string) => {
  if (accountId.length === 0) {
    return;
  }
  if (accounts.value.length > 0) {
    selectAccount(accounts.value[0].number, accounts.value[0].accountNumber);
  } else {
    selectAccount(accountId, accountId);
  }
};

const handleSuggestionSelected = (event: CustomEvent) => {
  findAccount(event.detail);
};

const handleInputValue = (event: CustomEvent) => {
  inputValue.value = event.detail;
  getAccountList();
};

const setAccounts = async (searchParam: string) => {
  error.value = false;
  try {
    loading.value = true;

    accounts.value = await searchAccounts(
      invoiceListData.value.scopeId,
     selectedOrganisation.value,
      searchParam
    );
  } catch {
    error.value = true;
    logError("b2b-invoice-landing-page", "Failed to fetch invoice accounts");
  } finally {
    loading.value = false;
  }
};
</script>
