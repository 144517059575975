import { logError } from "@telia/b2x-logging";
import { hasFunctionalAgreements } from "../services/corp-customer-billing-accounts";
import { getServiceWebs } from "@telia/b2b-logged-in-service";

export const hasSDW = async (scopeId: string, tscid: string) => {
  const hasServiceWebs = await tryToGetServiceWebs();
  if (!hasServiceWebs) return false;
  return await tryToGetHasFunctionalAgreements(scopeId, tscid);
};

const tryToGetServiceWebs = async () => {
  try {
    const getServiceWebsResponse = await getServiceWebs();
    return getServiceWebsResponse?.includes("SDW") || false;
  } catch {
    logError("b2b-invoice-landing-page", "Failed to get service webs");
    return false;
  }
};

const tryToGetHasFunctionalAgreements = async (scopeId: string, tscid: string) => {
  try {
    return await hasFunctionalAgreements(scopeId, [tscid]);
  } catch {
    logError("b2b-invoice-landing-page", "Failed to check if user has functional agreements");
    return false;
  }
};
