import { addNewToast } from "@telia/b2b-message-service";
import { ApiError } from "@telia/b2b-rest-client/dist/corp-scope-information";
import { logError } from "@telia/b2x-logging";
import { nextTick, ref } from "vue";
import { Invoice } from "../interfaces";
import { translateMixin } from "../locale";
import { getPdfSize } from "../services/corp-customer-invoices";
import { analytics, trackEventInvoices } from "../utils/analytics";
import { DOWNLOADABLE_PAGE_SIZE_LIMIT } from "../utils/pdf-size";

const showDownloadPdfDrawer = ref(false);
const pdfInformation = ref({
  scopeId: "",
  tscid: "",
  accountNumber: "",
  invoiceNumber: "",
  billingSystemID: "",
  pdfSize: 0,
});

const useDownloadPdfDrawer = () => {
  const t = translateMixin.methods.t;

  const openDownloadPdfDrawer = async (
    scopeId: string,
    tscid: string,
    rowData: Invoice
  ): Promise<void> => {
    try {
      trackEventInvoices(analytics.label.LADDA_NER, analytics.action.INITIATED);
      const pdfSize = await getPdfSize(
        scopeId,
        tscid,
        rowData.invoiceNumber,
        rowData.billingSystemID
      );

      if (pdfSize > DOWNLOADABLE_PAGE_SIZE_LIMIT) {
        pdfInformation.value = {
          scopeId,
          tscid,
          accountNumber: rowData.accountNumber,
          invoiceNumber: rowData.invoiceNumber,
          billingSystemID: rowData.billingSystemID,
          pdfSize,
        };

        await nextTick();
        showDownloadPdfDrawer.value = true;

        trackEventInvoices(analytics.label.FAKTURA_POPUP, analytics.action.OPEN);
      } else {
        const pdfUrl = createPdfUrl(rowData.invoiceNumber, rowData.billingSystemID, scopeId, tscid);
        window.location.assign(pdfUrl);
        trackEventInvoices(analytics.label.LADDA_NER, analytics.action.COMPLETED);
      }
    } catch (error) {
      addNewToast(
        "id",
        "error",
        t("table.feedback.error.default.header"),
        t("table.feedback.error.default.body")
      );
      trackEventInvoices(`{ status: ${(error as ApiError).status} }`, analytics.action.ERROR);
      logError("b2b-invoice-landing-page", "Failed to fetch download invoice pdf size");
    }
  };

  return { openDownloadPdfDrawer, showDownloadPdfDrawer, pdfInformation };
};

export default useDownloadPdfDrawer;

const createPdfUrl = (
  invoiceId: string,
  billingSystemId: string,
  scopeId: string,
  tscid: string
): string => {
  return `/.api/corp-customer-invoices/v1/${scopeId}/pdf?tscid=${tscid}&invoiceId=${invoiceId}&billingSystemID=${billingSystemId}`;
};
