<template>
  <div t-id="b2b-invoice-landing-page" id="invoiceLandingPage" class="invoice-landing-page">
    <feature-highlight
      v-if="
        !loading && !loadingTemplate && !showOnboardingModal && showBillingAccountFeatureHighlight
      "
    />
    <b2b-layout
      :showOrganisationSelector="true"
      :showOnboardingButton="true"
      :heading="t(`pageHeader.header`)"
      :pageTitle="t(`pageHeader.header`)"
      @organisationSelected="setSelectedOrganisationAndFetch"
      @onboardingButtonClicked="showOnboardingModal = true"
    >
      <div>
        <invoice-filter />
        <div
          t-id="invoice-list-telia-grid-wrapper"
          class="invoice-list-telia-grid-wrapper"
          :class="{ 'wide-table': isWideTable }"
        >
          <telia-grid id="tableContainer">
            <telia-row>
              <telia-col width="12">
                <div
                  v-if="loading && !layout.loadingTemplate"
                  class="b2b-invoice-table__mask"
                  t-id="invoice-list-loading-indicator"
                >
                  <b2x-spinner size="large"></b2x-spinner>
                </div>
                <invoice-table
                  v-if="!emptyList"
                  @is-wide-table="(event) => (isWideTable = event)"
                ></invoice-table>
                <telia-p t-id="invoice-list-error" v-if="error" class="b2b-invoice-table__error">
                  {{ t("table.feedback.error.invoices") }}
                </telia-p>
                <div
                  t-id="invoice-list-empty-wrapper"
                  v-if="emptyList"
                  class="b2b-invoice-table__empty-list"
                >
                  <telia-heading tag="h1" variant="title-300" t-id="invoice-list-empty-header">
                    {{ t("table.feedback.empty.header") }}
                  </telia-heading>
                  <telia-p variant="preamble-100" t-id="invoice-list-empty-ingress">
                    {{ t("table.feedback.empty.ingress") }}
                  </telia-p>
                  <telia-p t-id="invoice-list-empty-description">
                    {{ t("table.feedback.empty.description") }}
                  </telia-p>
                </div>
                <b2x-paginator
                  v-if="pagination.totalInvoices"
                  :list-length="pagination.totalInvoices"
                  :page-sizes="'[10, 25, 50, 100]'"
                  :default-page-size="pagination.pageSize"
                  t-id="invoice-table-paginator"
                  @paginationChange="paginationChange"
                ></b2x-paginator>
              </telia-col>
            </telia-row>
          </telia-grid>
        </div>
      </div>
    </b2b-layout>
    <onboarding-modal
      v-if="showOnboardingModal"
      @closeOnboardingModal="showOnboardingModal = false"
    />
    <download-pdf-drawer v-if="showDownloadPdfDrawer" />
  </div>
</template>

<script setup lang="ts">
import { isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { getTooltips } from "@telia/b2b-user-settings";
import { logError } from "@telia/b2x-logging";
import { computed, onBeforeMount, ref } from "vue";
import DownloadPdfDrawer from "./components/download-pdf-drawer/download-pdf-drawer.vue";
import FeatureHighlight from "./components/feature-highlight.vue";
import InvoiceFilter from "./components/invoice-filters/invoice-filter.vue";
import InvoiceTable from "./components/invoice-table.vue";
import OnboardingModal from "./components/onboarding/onboarding-modal.vue";
import useDownloadPdfDrawer from "./composables/download-pdf";
import useInvoiceListData from "./composables/invoice-list";
import useTableData from "./composables/table-data";
import { translateMixin, translateSetup } from "./locale/index";
import { BILLING_ACCOUNT_FEATURE_HIGHLIGHT_TOOLTIP_ID } from "./utils/tool-tip-id";

const {
  invoiceListData,
  layout,
  init,
  invoiceTablePaginationChange,
  setSelectedOrganisationAndFetch,
} = useInvoiceListData();
const { showDownloadPdfDrawer } = useDownloadPdfDrawer();
const { tableData } = useTableData();
const showOnboardingModal = ref(false);
const isWideTable = ref(false);
const showBillingAccountFeatureHighlight = ref(false);

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
  await init();
  await tryToSetShowBillingAccountFeatureHighlight();
  shouldShowOnboardingModal();
});

const t = translateMixin.methods.t;

const loading = computed(() => {
  return layout.value.showLoading;
});

const error = computed(() => {
  return layout.value.showError;
});

const loadingTemplate = computed(() => {
  return layout.value.loadingTemplate;
});

const emptyList = computed(() => {
  return (
    !loading.value &&
    !error.value &&
    !loadingTemplate.value &&
    tableData.value.length === 0 &&
    (pagination.value.totalInvoices <= 0 ||
      pagination.value.totalInvoices <= pagination.value.pageSize ||
      pagination.value.totalInvoices <= invoiceListData.value.invoiceTo)
  );
});

const pagination = computed(() => {
  return invoiceListData.value.pagination;
});

const paginationChange = (event: CustomEvent) => {
  invoiceTablePaginationChange(event.detail);
};

const shouldShowOnboardingModal = () => {
  if (!window.localStorage.getItem("invoiceOnboardingWatched")) {
    showOnboardingModal.value = true;
  }
};

const tryToSetShowBillingAccountFeatureHighlight = async () => {
  try {
    if (await isTeliaAdmin()) return;
    const response = await getTooltips(invoiceListData.value.scopeId);
    showBillingAccountFeatureHighlight.value =
      !response?.includes(BILLING_ACCOUNT_FEATURE_HIGHLIGHT_TOOLTIP_ID) ?? true;
  } catch {
    logError("b2b-invoice-landing-page", "Failed to fetch invoice account feature highlight");
  }
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/colors/variables.scss";

.invoice-landing-page {
  .invoice-list-telia-grid-wrapper telia-grid {
    transition: max-width 0.3s ease-in;
  }
  .invoice-list-telia-grid-wrapper.wide-table telia-grid {
    max-width: 100%;
  }

  telia-grid#tableContainer {
    position: relative;
    margin-top: 3rem;
    min-height: 20rem;
  }

  .b2b-invoice-table__mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .b2b-invoice-table__error,
  .b2b-invoice-table__empty-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
    telia-p {
      padding: $telia-spacing-20 0;
    }
  }
}
</style>
