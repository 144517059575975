<template>
  <div>
    <telia-text-input
      t-id="invoice-filter-option-invoice-number"
      ref="invoiceNrInput"
      type="search"
      :label="t('mybusiness.invoice.number')"
      :error-message="
        invalidInvoiceNumber ? t('table.filterPanel.invoiceFilter.validationError') : null
      "
      maxlength="255"
      @keyup.enter="validateThenClearAndFetch($event.srcElement.value)"
      @input="handleInputValue"
      :value="inputValue"
    >
    </telia-text-input>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import useInvoiceListData from "../../../composables/invoice-list";
import { translateMixin } from "../../../locale";
import validate from "../../../utils/invoice-number-validation";

const t = translateMixin.methods.t;
const { setInvoiceNrAndFetch } = useInvoiceListData();
const isInputFieldEmpty = ref(true);
const invalidInvoiceNumber = ref(false);
const invoiceNrInput = ref();
const inputValue = ref("");

const validateThenClearAndFetch = (invoiceNr: string) => {
  if (invoiceNr.length > 0) {
    invalidInvoiceNumber.value = !validate(invoiceNr);
    if (!invalidInvoiceNumber.value) {
      isInputFieldEmpty.value = true;
      inputValue.value = "";
      setInvoiceNrAndFetch(invoiceNr);
    }
  }
};

const handleInputValue = (event) => {
  if (event?.target?.value?.length === 0) {
    isInputFieldEmpty.value = true;
    invalidInvoiceNumber.value = false;
  } else {
    inputValue.value = event.target.value;
    isInputFieldEmpty.value = false;
  }
};
</script>

<style scoped lang="scss">
telia-text-input {
  position: relative;
}
</style>
