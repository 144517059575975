<template>
  <div t-id="feature-highlight" id="feature-highlight">
    <b2x-feature-highlight
      :show-popover="show"
      :body="t('featureHighlight.body')"
      :heading="t('featureHighlight.header')"
      :placement="placement"
      @afterClosePopover="closeBillingAccountFeatureHighlight"
    >
    </b2x-feature-highlight>
  </div>
</template>

<script setup lang="ts">
import { isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { markTooltipAsSeen } from "@telia/b2b-user-settings";
import { logError } from "@telia/b2x-logging";
import { onMounted, ref } from "vue";
import useInvoiceListData from "../composables/invoice-list";
import { translateMixin } from "../locale";
import { BILLING_ACCOUNT_FEATURE_HIGHLIGHT_TOOLTIP_ID } from "../utils/tool-tip-id";

const t = translateMixin.methods.t;
const placement = ref("bottom-end");
const { invoiceListData } = useInvoiceListData();
const show = ref(false);

onMounted(() => {
  show.value = true;
  let interValId: number;
  interValId = setInterval(() => {
    const navLink = document.querySelector("b2x-sspa-link[href$='fakturor/hantera-fakturering']");

    if (navLink && !show.value) {
      show.value = true;
      return;
    }

    const el = document.querySelector("b2x-feature-highlight");
    const featureHighlight = document.getElementById("feature-highlight");
    if (featureHighlight && el) {
      positionTheFeatureHighlight(featureHighlight, navLink);
      const slot = el.shadowRoot?.querySelector(".b2x-feature-highlight__slot--highlight");
      (slot as HTMLElement).style.padding = "0";
      clearInterval(interValId);
    }
  }, 20);
});

const positionTheFeatureHighlight = (featureHighlight, navLink) => {
  const smallScreenSize = window.matchMedia("(max-width: 700px)");
  const mediumScreenSize = window.matchMedia("(min-width: 700px) and (max-width: 1024px)");
  const largeScreenSize = window.matchMedia("(min-width: 1024px)");

  smallScreenSize.onchange = (event) => {
    if (event.matches) {
      handleSmallScreen(featureHighlight, navLink);
    }
  };

  mediumScreenSize.onchange = (event) => {
    if (event.matches) {
      handleMediumAndLargeScreen(featureHighlight, navLink);
    }
  };

  largeScreenSize.onchange = (event) => {
    if (event.matches) {
      handleMediumAndLargeScreen(featureHighlight, navLink);
    }
  };

  smallScreenSize.matches
    ? handleSmallScreen(featureHighlight, navLink)
    : handleMediumAndLargeScreen(featureHighlight, navLink);
};

const handleSmallScreen = (featureHighlight, navLink) => {
  const navLinkRect = navLink.getBoundingClientRect();
  const featureHighlightRect = featureHighlight.getBoundingClientRect();
  const leftAdjustment = 40;

  const top = navLinkRect.top + navLinkRect.height;
  const left =
    navLinkRect.left + navLinkRect.width - featureHighlightRect.width / 2 - leftAdjustment;
  placement.value = "bottom";
  featureHighlight.style.top = `${top}px`;
  featureHighlight.style.left = `${left}px`;
  setTimeout(() => {
    featureHighlight.firstChild.shadowRoot.querySelector(
      ".b2x-feature-highlight__popover-arrow"
    ).style.top = `${top - featureHighlightRect.top}px`;
  }, 150);
};

const handleMediumAndLargeScreen = (featureHighlight, navLink) => {
  const navLinkRect = navLink.getBoundingClientRect();
  const featureHighlightRect = featureHighlight.getBoundingClientRect();
  const leftAdjustment = 40;

  const top = navLinkRect.top + navLinkRect.height;
  const left =
    navLinkRect.left + navLinkRect.width / 2 - featureHighlightRect.width / 2 - leftAdjustment;
  placement.value = "bottom-end";
  featureHighlight.style.top = `${top}px`;
  featureHighlight.style.left = `${left}px`;
  featureHighlight.firstChild.shadowRoot.querySelector(
    ".b2x-feature-highlight__popover-arrow"
  ).style.top = `${top - featureHighlightRect.top}px`;
};

const closeBillingAccountFeatureHighlight = () => {
  show.value = false;
  tryToSaveBillingAccountFeatureHighlight();
};

const tryToSaveBillingAccountFeatureHighlight = async () => {
  try {
    if (await isTeliaAdmin()) return;
    markTooltipAsSeen(invoiceListData.value.scopeId, BILLING_ACCOUNT_FEATURE_HIGHLIGHT_TOOLTIP_ID);
  } catch (e) {
    logError("b2b-invoice-landing-page", "Failed to set invoice account feature highlight");
  }
};
</script>

<style lang="scss" scoped>
div#feature-highlight {
  position: absolute;
}
</style>
