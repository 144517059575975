export const PAYMENT_STATUS_BADGE = {
  UNKNOWN_STATUS: {
    variant: "neutral",
    translationKey: "table.tableData.UNKNOWN_STATUS",
  },
  PARTLY_PAID_OR_CREDITED: {
    variant: "neutral",
    translationKey: "table.tableData.PARTLY_PAID_OR_CREDITED",
  },
  FULLY_PAID: {
    variant: "positive",
    translationKey: "table.tableData.FULLY_PAID",
  },
  NOT_PAID: {
    variant: "caution",
    translationKey: "table.tableData.NOT_PAID",
  },
  OVERDUE: {
    variant: "warning",
    translationKey: "table.tableData.OVERDUE",
  },
} as const;
