import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

const toggles = {
  featureToggleDownloadXml: false,
};

export default {
  isFeatureToggleDownloadXmlEnabled: () => isEnabled("featureToggleDownloadXml"),
};

function isEnabled(toggleName) {
  return featureToggleIsEnabled(toggleName, toggles);
}
