<template>
  <b2x-onboarding
    t-id="b2x-onboarding"
    :is-open="isOpen"
    @closeModal="onCloseModal"
    @nextStepClick="trackOnboardingNextStep"
    @previousStepClick="trackOnboardingPreviousStep"
  >
    <b2x-onboarding-step
      :key="stepIndex"
      v-for="(step, stepIndex) in steps"
      :src="step"
      :heading="t(`onboardingModal.slide_${stepIndex}_title`)"
      :description="t(`onboardingModal.slide_${stepIndex}_desc`)"
    ></b2x-onboarding-step>
  </b2x-onboarding>
</template>

<script setup lang="ts">
import "@telia/b2x-onboarding";
import { nextTick, onMounted, ref } from "vue";
import { translateMixin } from "../../locale";
import { analytics, trackEventOnboardingModal } from "../../utils/analytics";
import gif1 from "./img/onboarding_1.gif";
import gif2 from "./img/onboarding_2.gif";
import gif3 from "./img/onboarding_3.gif";
import gif4 from "./img/onboarding_4.gif";

const t = translateMixin.methods.t;

const steps = ref([gif1, gif2, gif3, gif4]);
const isOpen = ref(false);

onMounted(async () => {
  await nextTick();
  isOpen.value = true;
  trackOpenModal();
});

const emit = defineEmits<{ (e: "closeOnboardingModal"): void }>();

const onCloseModal = () => {
  window.localStorage.setItem("invoiceOnboardingWatched", "1");
  isOpen.value = false;
  trackCloseModal();
  emit("closeOnboardingModal");
};

const trackOpenModal = () => {
  trackEventOnboardingModal(analytics.label.OPEN, analytics.action.OPEN);
};

const trackCloseModal = () => {
  trackEventOnboardingModal(analytics.label.CLOSED, analytics.action.CLICK);
};

const trackOnboardingNextStep = () => {
  trackEventOnboardingModal(analytics.label.NEXT, analytics.action.CLICK);
};

const trackOnboardingPreviousStep = () => {
  trackEventOnboardingModal(analytics.label.PREVIOUS, analytics.action.CLICK);
};
</script>
