const validate = (invoiceNumber: number | string) => {
  const invNr: number = typeof invoiceNumber === "string" ? parseInt(invoiceNumber) : invoiceNumber;

  return ACCEPT_INVOICE_NUMBER_INTERVALS.some(
    (interval) =>
      isLowerThenHighestNumberInInterval(invNr, interval.highestNumberInInterval) &&
      isHigherThenLowestNumberInInterval(invNr, interval.lowestNumberInInterval)
  );
};

const isLowerThenHighestNumberInInterval = (
  invoiceNumber: number,
  highestNumberInInterval: number
) => {
  return invoiceNumber <= highestNumberInInterval;
};

const isHigherThenLowestNumberInInterval = (
  invoiceNumber: number,
  lowestNumberInInterval: number
) => {
  return invoiceNumber >= lowestNumberInInterval;
};

const ACCEPT_INVOICE_NUMBER_INTERVALS = [
  { lowestNumberInInterval: 2490000000, highestNumberInInterval: 2499999999 },
  { lowestNumberInInterval: 97000000000, highestNumberInInterval: 97149999999 },
  { lowestNumberInInterval: 19000000000, highestNumberInInterval: 51999999999 },
  { lowestNumberInInterval: 60300000000, highestNumberInInterval: 86699999999 },
  { lowestNumberInInterval: 10000000000, highestNumberInInterval: 14699999999 },
  { lowestNumberInInterval: 700000000000, highestNumberInInterval: 719999999999 },
  { lowestNumberInInterval: 150000000000, highestNumberInInterval: 550000009999 },
  { lowestNumberInInterval: 800000000000, highestNumberInInterval: 809999999999 },
];

export default validate;
