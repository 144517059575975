import { corpCustomerInvoices } from "@telia/b2b-rest-client";
import {
  Invoice,
  InvoiceInformation,
  Subscription,
  InvoiceParams,
  SearchParams,
} from "../interfaces";

export const fetchInvoices = (queryParameters: InvoiceParams): Promise<InvoiceInformation> => {
  return new Promise<InvoiceInformation>((resolve, reject) => {
    corpCustomerInvoices.CorpCustomerInvoicesService.getInvoiceInformation(
      queryParameters.scopeId,
      queryParameters.tscid,
      queryParameters.fromInvoiceDate,
      queryParameters.toInvoiceDate,
      queryParameters.invoiceFrom,
      queryParameters.invoiceTo,
      queryParameters.invoiceNumber,
      queryParameters.subscriptionNumber,
      queryParameters.accountNumber
    )
      .then((data: corpCustomerInvoices.InvoiceListResponse) => {
        const mappedData = {
          invoiceList: mapInvoiceMetalistToInvoiceList(data.invoiceMetaList),
          paginateTotalInvoices: data.paginateTotalInvoices || 0,
        };
        resolve(mappedData);
      })
      .catch((e: corpCustomerInvoices.ApiError) => reject(e));
  });
};

export const getPdfSize = (
  scopeId: string,
  tscid: string,
  invoiceId: string,
  billingSystemID: string
): Promise<number> => {
  return new Promise<number>((resolve, reject) => {
    corpCustomerInvoices.CorpCustomerInvoicesService.getPdfSize(
      scopeId,
      tscid,
      invoiceId,
      billingSystemID
    )
      .then((data: corpCustomerInvoices.PdfSizeResponse) => resolve(data.pageTotal || 0))
      .catch((e: corpCustomerInvoices.ApiError) => reject(e));
  });
};

export const searchSubscriptions = (queryParameters: SearchParams): Promise<Subscription[]> => {
  return new Promise((resolve, reject) => {
    corpCustomerInvoices.CorpCustomerInvoicesService.searchSubscription(
      queryParameters.scopeId,
      queryParameters.tscid,
      queryParameters.searchParam,
      queryParameters.accountNumber
    )
      .then((data: corpCustomerInvoices.SubscriptionList) => {
        resolve(mapSubscriptions(data));
      })
      .catch((e: corpCustomerInvoices.ApiError) => reject(e));
  });
};

const mapInvoiceMetalistToInvoiceList = (
  metalist: corpCustomerInvoices.InvoiceInformation[] | undefined
): Invoice[] => {
  if (metalist) {
    return metalist.flatMap((invoiceMetaList) => {
      if (invoiceMetaList.invoiceList) {
        return invoiceMetaList.invoiceList.map((invoice) => {
          return {
            invoiceNumber: invoice.invoiceNumber || "",
            invoiceDate: invoice.invoiceDate || "",
            invoiceDueDate: invoice.invoiceDueDate || "",
            totalAmount: invoice.totalAmount || 0,
            paymentStatus: invoice.paymentStatus || "",
            billingSystemID: invoice.billingSystemID || "",
            accountNumber: invoiceMetaList.accountNumber || "",
          };
        });
      } else {
        return [];
      }
    });
  } else {
    return [];
  }
};

const mapSubscriptions = (data: corpCustomerInvoices.SubscriptionList): Subscription[] => {
  // @ts-expect-error searchSubscription return type is not wrong
  return data.subscriptionList.map((subscription: corpCustomerInvoices.Subscription) => ({
    billingAccountNumber: subscription.billingAccountNumber ?? "",
    subscriptionid: subscription.subscriptionid ?? "",
    category: subscription.category ?? "",
  }));
};
