import {
  action,
  category,
  customDimensions,
  label as Label,
  trackEvent as TrackEvent,
} from "@telia/b2b-web-analytics-wrapper";
import { ICustomDimension } from "@telia/b2b-web-analytics-wrapper/types/interfaces";

const analytic_labels = {
  LABEL_EXPORT: "Export",
  EXPORT_MORE_THAN_500: ">500",
  EXPORT_LESS_THAN_500: "<500",
};

const label = { ...Label, ...analytic_labels };

export const analytics = { category, action, label, customDimensions };

export const trackEventInvoices = async (
  label: string,
  action: string,
  customDimensions: ICustomDimension[] | undefined = undefined,
  value?: string | undefined
): Promise<void> => {
  await TrackEvent(analytics.category.INVOICE, action, label, value, customDimensions);
};

export const trackExportTableDataGa = (action: string, exportPaymentStatus: boolean): void => {
  const value = exportPaymentStatus
    ? analytic_labels.EXPORT_LESS_THAN_500
    : analytic_labels.EXPORT_MORE_THAN_500;
  trackEventInvoices(analytic_labels.LABEL_EXPORT, action, undefined, value);
};

export const trackEventOnboardingModal = (label: string, action: string): void => {
  TrackEvent(analytics.category.INVOICE_ONBOARDING_MODAL, action, label);
};
