<template>
  <div
    class="subscription-finder"
    id="subscription-search"
    t-id="invoice-filter-option-invoice-subscription-number"
  >
    <b2x-input-suggestions
      t-id="b2x-input-suggestions"
      ref="invoiceSubscriptionNumberFilter"
      :label="t('mybusiness.subscription')"
      :loading="loading"
      :disabled="disabled"
      :suggestions="subscriptionsList"
      :error="subscriptionsError"
      @inputValue="handleInputValue"
      @suggestionSelected="handleSuggestionSelected"
    ></b2x-input-suggestions>
  </div>
</template>

<script setup lang="ts">
import { logError } from "@telia/b2x-logging";
import { computed, ref } from "vue";
import useInvoiceListData from "../../../composables/invoice-list";
import { Subscription } from "../../../interfaces";
import { translateMixin } from "../../../locale";
import { searchSubscriptions } from "../../../services/corp-customer-invoices";

const t = translateMixin.methods.t;
const { invoiceListData, layout, setSubscriptionIdAndFetch, selectedOrganisation } = useInvoiceListData();
const subscriptions = ref<Subscription[]>([]);
const inputValue = ref("");
const invoiceSubscriptionNumberFilter = ref();
const error = ref(false);
const loading = ref(false);

const subscriptionsList = computed(() => {
  const mappedSubscriptions = subscriptions.value
    .map((subscription) => {
      return subscription.subscriptionid;
    })
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return JSON.stringify(mappedSubscriptions);
});

const subscriptionsError = computed(() => {
  return error.value ? t("table.filterPanel.subscription.filterError") : "";
});

const disabled = computed(() => {
  return layout.value.showLoading;
});

const getSubscriptionList = () => {
  inputValue.value.length > 2 ? setSubscriptions(inputValue.value) : clearSubscriptionList();
};

const selectSubscription = (subscriptionId: string) => {
  if (subscriptionId.length > 0 && !loading.value) {
    subscriptions.value = [];
    setSubscriptionIdAndFetch(subscriptionId);
    clearInput();
  }
};

const findSubscription = (subscriptionId: string) => {
  const subscriptionToSelect = subscriptions.value.find(
    (subscription) => subscription.subscriptionid === subscriptionId
  );

  if (subscriptionToSelect) {
    selectSubscription(subscriptionToSelect.subscriptionid);
  } else {
    searchBDLx(subscriptionId);
  }
};

const clearSubscriptionList = () => {
  subscriptions.value = [];
};

const searchBDLx = (subscriptionId: string) => {
  if (subscriptionId.length === 0) {
    return;
  }

  if (subscriptions.value.length > 0) {
    selectSubscription(subscriptions.value[0].subscriptionid);
  } else {
    subscriptions.value = [];
    setSubscriptionIdAndFetch(subscriptionId);
    clearInput();
  }
};

const clearInput = () => {
  if (!invoiceSubscriptionNumberFilter.value.shadowRoot) return;
  invoiceSubscriptionNumberFilter.value.shadowRoot.querySelector("input").value = "";
};

const handleInputValue = (event: CustomEvent) => {
  inputValue.value = event.detail;
  getSubscriptionList();
};

const handleSuggestionSelected = (event: CustomEvent) => {
  findSubscription(event.detail);
};

const setSubscriptions = async (searchParam: string) => {
  try {
    const queryParameters = createSearchQueryParameters(searchParam);
    error.value = false;
    loading.value = true;
    subscriptions.value = await searchSubscriptions(queryParameters);
  } catch {
    error.value = true;
    logError("b2b-invoice-landing-page", "Failed to fetch invoice subscriptions");
  } finally {
    loading.value = false;
  }
};

const createSearchQueryParameters = (searchParam: string) => {
  return {
    scopeId: invoiceListData.value.scopeId,
    tscid: selectedOrganisation.value,
    searchParam: searchParam,
    // Currently sending M-number due to
    // BE not being able to filter on "real" account nr from c2bCache
    accountNumber: invoiceListData.value.account.billingAccountNr
      ? invoiceListData.value.account.billingAccountNr
      : undefined,
  };
};
</script>
