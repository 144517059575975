<template>
  <b2x-drawer
    t-id="download-pdf-drawer"
    :heading="t('pdfLinks.header')"
    position="right"
    :is-open="isOpen"
    @drawerClose="closeDownloadPdfDrawer"
  >
    <invoice-information
      :accountNumber="pdfInformation.accountNumber"
      :invoiceNumber="pdfInformation.invoiceNumber"
    />
    <pdf-links
      :pdfHref="pdfHref"
      :totalLinks="totalLinks"
      :pdfSize="pdfInformation.pdfSize"
      gaLabel="Ladda ner"
      @sendGa="sendGa"
    />
  </b2x-drawer>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from "vue";
import useDownloadPdfDrawer from "../../composables/download-pdf";
import { translateMixin } from "../../locale";
import { analytics, trackEventInvoices } from "../../utils/analytics";
import { DOWNLOADABLE_PAGE_SIZE_LIMIT } from "../../utils/pdf-size";
import invoiceInformation from "./invoice-information.vue";
import PdfLinks from "./pdf-links.vue";

const t = translateMixin.methods.t;
const { pdfInformation, showDownloadPdfDrawer } = useDownloadPdfDrawer();

const isDrawerOpen = ref(false);

onMounted(async () => {
  await nextTick();
  isDrawerOpen.value = true;
});

const isOpen = computed(() => {
  return showDownloadPdfDrawer.value && isDrawerOpen.value;
});

const scopeId = computed(() => {
  return pdfInformation.value.scopeId;
});

const tscid = computed(() => {
  return pdfInformation.value.tscid;
});

const totalLinks = computed(() => {
  return Math.ceil(pdfInformation.value.pdfSize / DOWNLOADABLE_PAGE_SIZE_LIMIT);
});

const pdfHref = computed(() => {
  return `/.api/corp-customer-invoices/v1/${scopeId.value}/pdf?tscid=${tscid.value}&invoiceId=${pdfInformation.value.invoiceNumber}&billingSystemID=${pdfInformation.value.billingSystemID}`;
});

const closeDownloadPdfDrawer = () => {
  isDrawerOpen.value = false;
  showDownloadPdfDrawer.value = false;
};

const sendGa = (label: string) => {
  trackEventInvoices(label, analytics.action.COMPLETED);
};
</script>
