const exportFormData = (
  exportFormAction: string,
  currentLanguage: string,
  fromInvoiceDate: string,
  toInvoiceDate: string,
  invoiceNr: string,
  selectedSubscriptionId: string,
  selectedAccountNr: string,
  exportPaymentStatus: string
) => {
  const form = document.createElement("form");

  form.method = "POST";
  form.action = exportFormAction;

  addInputElementToForm(form, currentLanguage, "currentLanguage");
  addInputElementToForm(form, fromInvoiceDate, "fromInvoiceDate");
  addInputElementToForm(form, toInvoiceDate, "toInvoiceDate");
  addInputElementToForm(form, invoiceNr, "invoiceNumber");
  addInputElementToForm(form, selectedSubscriptionId, "subscriptionNumber");
  addInputElementToForm(form, selectedAccountNr, "accountNumber");
  addInputElementToForm(form, (exportPaymentStatus as unknown) as string, "paymentStatus");

  document.body.appendChild(form);

  form.submit();
  form.remove();
};

const addInputElementToForm = (form: HTMLFormElement, value: string, name: string) => {
  const element = document.createElement("input");
  element.value = value;
  element.name = name;
  form.appendChild(element);
};

export default exportFormData;
