import { InvoiceListData, Layout } from "../interfaces";
import { createFromDate } from "./use-date";

export const createInvoiceListData = (): InvoiceListData => ({
  scopeId: "",
  hasSDW: false,
  invoiceFrom: 1,
  invoiceTo: 25,
  invoicesList: [],
  invoiceNr: "",
  organisation: {
    tscid: "",
    name: "",
    number: "",
  },
  selectedSubscriptionId: "",
  account: {
    accountNr: "",
    billingAccountNr: "",
  },
  pagination: {
    totalInvoices: 0,
    pageSize: 25,
  },
  to_date: new Date(),
  from_date: createFromDate(3),
});

export const createLayout = (): Layout => ({
  showLoading: true,
  loadingTemplate: true,
  showError: false,
});
