<template>
  <section id="invoiceFilter" t-id="invoice-filter-panel">
    <telia-grid>
      <telia-fieldset
        t-id="filter-fieldset"
        :disabled="loading"
        class="invoice-filter__options"
        :legend="t('table.filterPanel.legend')"
      >
        <div class="sdw-link-wrapper">
          <b2x-sspa-link
            variant="standalone"
            t-id="invoice-filter-panel-sdw-link"
            target="_blank"
            v-if="showSDWLink"
            href="https://servicedeskweb.telia.se/Reports/Default.aspx"
            @click="trackSDWLink"
          >
            <span>{{ t("table.filterPanel.sdwLink") }}</span>
            <telia-icon size="sm" name="external"></telia-icon>
          </b2x-sspa-link>
        </div>
        <div class="invoice-filter__options" t-id="invoice-filter__options">
          <component :is="loadInvoiceNumberFilter"></component>
          <component :is="loadInvoiceSubscriptionNumberFilter"></component>
          <component :is="loadBillingAccountNumberFilter"></component>
          <component :is="loadDateFilter"></component>
        </div>
      </telia-fieldset>
      <active-filters></active-filters>
    </telia-grid>
  </section>
</template>

<script setup lang="ts">
import "@telia/b2x-skeleton-input";
import { computed } from "vue";
import useInvoiceListData from "../../composables/invoice-list";
import { translateMixin } from "../../locale";
import ActiveFilters from "./components/active-filters.vue";
import BillingAccountNumberFilter from "./components/billing-account-number-filter.vue";
import DateRangeFilter from "./components/date-range-filter.vue";
import InvoiceNumberFilter from "./components/invoice-number-filter.vue";
import InvoiceSubscriptionNumberFilter from "./components/invoice-subscription-number-filter.vue";
import { analytics, trackEventInvoices } from "../../utils/analytics";

const t = translateMixin.methods.t;
const { invoiceListData, layout } = useInvoiceListData();
const trackSDWLink = () => {
  //@ts-expect-error "SDW" needs to be added to analytics customDimensions
  trackEventInvoices(analytics.category.INVOICE, analytics.action.CLICK, "SDW");
};

const loading = computed(() => {
  return layout.value.showLoading;
});

const loadingTemplate = computed(() => {
  return layout.value.loadingTemplate;
});

const loadInvoiceNumberFilter = computed(() => {
  return loadingTemplate.value ? "b2x-skeleton-input" : InvoiceNumberFilter;
});

const loadInvoiceSubscriptionNumberFilter = computed(() => {
  return loadingTemplate.value ? "b2x-skeleton-input" : InvoiceSubscriptionNumberFilter;
});

const loadBillingAccountNumberFilter = computed(() => {
  return loadingTemplate.value ? "b2x-skeleton-input" : BillingAccountNumberFilter;
});

const loadDateFilter = computed(() => {
  return loadingTemplate.value ? "b2x-skeleton-input" : DateRangeFilter;
});

const showSDWLink = computed(() => {
  return invoiceListData.value.hasSDW;
});
</script>

<style lang="scss" module>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/breakpoints/variables";
@import "~@teliads/components/foundations/typography/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

section#invoiceFilter {
  font-family: $telia-typography-telia-sans-font-family;

  div.invoice-filter__options {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;

    > * {
      min-height: $telia-spacing-96;
    }

    @media screen and (min-width: 68rem) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  telia-p {
    padding-bottom: $telia-spacing-24;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  fieldset {
    //Override
    padding: 0;
    legend,
    div.sdw-link-wrapper {
      width: 50%;
    }

    legend {
      float: left;
      margin: $telia-spacing-12 0 $telia-spacing-24;
    }

    div.sdw-link-wrapper {
      float: right;
      display: flex;
      justify-content: flex-end;

      b2x-sspa-link {
        display: flex;
        > span {
          padding-right: $telia-spacing-4;
        }
        > telia-icon {
          padding-top: $telia-spacing-2;
        }
      }
    }
  }
}
</style>
