<template>
  <div class="invoice-filter__options__date" t-id="invoice-filter-option-date">
    <b2x-date-range-picker
      t-id="date-range-picker"
      :label="t('table.filterPanel.dateFilter.label')"
      :start-date="startDate"
      :end-date="endDate"
      :reset-start-date="getDateLocaleString(threeMonthsAgo)"
      :reset-end-date="getDateLocaleString(maxDate)"
      :min="getDateLocaleString(minDate)"
      :max="getDateLocaleString(maxDate)"
      @popoverClosed="popoverClosed"
      @startDateChanged="startDateChanged"
      @endDateChanged="endDateChanged"
    />
  </div>
</template>

<script setup lang="ts">
import "@telia/b2x-date-range-picker";
import { computed, ref } from "vue";
import useInvoiceListData from "../../../composables/invoice-list";
import { translateMixin } from "../../../locale";
import { createFromDate, setToLocaleDateString } from "../../../utils/use-date";

const t = translateMixin.methods.t;
const { invoiceListData, setDatesAndFetch } = useInvoiceListData();
const minDate = ref<Date>(createFromDate(18));
const maxDate = ref<Date>(new Date());
const threeMonthsAgo = createFromDate(3);
const newStartDate = ref({ value: "", valueAsDate: threeMonthsAgo });
const newEndDate = ref({ value: "", valueAsDate: new Date() });

const startDate = computed(() => {
  return getDateLocaleString(invoiceListData.value.from_date);
});

const endDate = computed(() => {
  return getDateLocaleString(invoiceListData.value.to_date);
});

const hasSelectedDatesChanged = () => {
  if (newStartDate.value.valueAsDate < minDate.value) {
    return false;
  }

  if (newStartDate.value.valueAsDate > newEndDate.value.valueAsDate) {
    return false;
  }

  return (
    invoiceListData.value.from_date.toDateString() !==
      newStartDate.value.valueAsDate.toDateString() ||
    invoiceListData.value.to_date.toDateString() !== newEndDate.value.valueAsDate.toDateString()
  );
};

const shouldResetDates = () => {
  return (
    getDateLocaleString(threeMonthsAgo) === newStartDate.value.value &&
    getDateLocaleString(maxDate.value) === newEndDate.value.value
  );
};

const popoverClosed = () => {
  if (hasSelectedDatesChanged()) {
    setDatesAndFetch(
      newStartDate.value.valueAsDate,
      newEndDate.value.valueAsDate,
      t("table.filterPanel.dateFilter.separator"),
      shouldResetDates()
    );
  }
};

const getDateLocaleString = (date: Date) => {
  return setToLocaleDateString(date);
};

const startDateChanged = (event: CustomEvent) => {
  newStartDate.value = event.detail;
};

const endDateChanged = (event: CustomEvent) => {
  newEndDate.value = event.detail;
};
</script>
