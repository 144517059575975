import { corpCustomerBillingAccounts } from "@telia/b2b-rest-client";
import { BillingAccount } from "../interfaces";
import { FunctionalAgreementResponse } from "@telia/b2b-rest-client/dist/corp-customer-billing-accounts";

export const searchAccounts = (
  scopeId: string,
  tscid: string,
  searchParam: string
): Promise<BillingAccount[]> => {
  return new Promise((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.searchBillingAccount(
      scopeId,
      tscid,
      searchParam
    )
      .then((data: corpCustomerBillingAccounts.GetBillingAccountsForTscidDTO[]) => {
        const mappedBillingAccountList: BillingAccount[] = data.map(
          (account: corpCustomerBillingAccounts.GetBillingAccountsForTscidDTO) => ({
            number: account.number || "",
            accountNumber: account.accountNumber || "",
          })
        );
        resolve(mappedBillingAccountList);
      })
      .catch((e: corpCustomerBillingAccounts.ApiError) => reject(e));
  });
};

export const hasFunctionalAgreements = (
  scopeId: string,
  tscids: Array<string>
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.checkForFunctionalAgreements(
      scopeId,
      tscids
    )
      .then((data: FunctionalAgreementResponse) => resolve(data.hasAgreements || false))
      .catch((e: corpCustomerBillingAccounts.ApiError) => reject(e));
  });
};
