export const createFromDate = (months: number): Date => {
  const from_date = new Date();
  from_date.setHours(0, 0, 0, 0);
  from_date.setMonth(from_date.getMonth() - months);
  return from_date;
};

export const setToLocaleDateString = (date: Date): string => {
  return date.toLocaleDateString("sv-SE");
};

export const formatAmountToLocaleString = (amount: number): string => {
  return amount.toLocaleString("sv-SE") + " SEK";
};
