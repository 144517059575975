<template>
  <b2x-active-filters
    t-id="b2x-active-filters"
    :filter-list="JSON.stringify(activeFilters)"
    :show-active-filter="JSON.stringify(activeFilters.length > 0)"
    @clearAllFiltersEvent="resetActiveFilters"
    @clearFilterEvent="removeFilter"
  ></b2x-active-filters>
</template>

<script setup lang="ts">
import "@telia/b2x-active-filters";
import useInvoiceListData from "../../../composables/invoice-list";

const {
  removeFilterByKey,
  removeAllFilters,
  fetchAndSetInvoiceList,
  activeFilters,
} = useInvoiceListData();

const removeFilter = (event: CustomEvent) => {
  removeFilterByKey(event.detail?.key);
  fetchAndSetInvoiceList();
};

const resetActiveFilters = () => {
  removeAllFilters();
  fetchAndSetInvoiceList();
};
</script>
