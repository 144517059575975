<template>
  <section t-id="invoice-table">
    <b2x-controlled-table
      t-id="b2x-controlled-table"
      :columns="JSON.stringify(tableHeaders)"
      :data="JSON.stringify(tableData)"
      :is-loading="tableIsLoading"
      :message="missingInvoiceOnPageText"
      show-expand-button="true"
      :is-wide-table="isWideTable"
      :show-filters="JSON.stringify(true)"
      @controlledTableExpandClicked="toggleIsWideTable"
      @buttonClicked="handleTableButtonClicked"
      :export-btn-label="t('mybusiness.export')"
      :export-types="JSON.stringify(types)"
      :export-line-label="exportLineLabel"
      @exportData="exportData"
      @exportPopoverOpened="exportPopoverOpened"
      :table-rows-count="getTableRowsCount"
      :table-rows-limit="getTableRowsLimit"
    >
      <div v-if="!exportPaymentStatus" slot="export-popover" t-id="invoice-export-popup-slot">
        <div class="export-slot">
          <telia-icon class="icon" name="alert"></telia-icon>
          <telia-p variant="paragraph-100" disable-hypenation>
            {{ t("table.export.informationText") }}
          </telia-p>
        </div>
      </div>
    </b2x-controlled-table>
  </section>
</template>

<script setup lang="ts">
import { currentLanguage } from "@telia/b2b-i18n";
import "@telia/b2x-table";
import { computed, nextTick, ref } from "vue";
import useDownloadPdfDrawer from "../composables/download-pdf";
import useInvoiceListData from "../composables/invoice-list";
import useTableData from "../composables/table-data";
import isEnabled from "../featureToggles";
import { translateMixin } from "../locale/index";
import { analytics, trackExportTableDataGa } from "../utils/analytics";
import exportFormData from "../utils/export-data";
import { setToLocaleDateString } from "../utils/use-date";

const t = translateMixin.methods.t;
const { invoiceListData, layout, isInvoicesMissingOnPage, selectedOrganisation } =
  useInvoiceListData();

const { openDownloadPdfDrawer } = useDownloadPdfDrawer();
const types = ref([""]);
const fromInvoiceDate = computed(() => setToLocaleDateString(invoiceListData.value.from_date));
const toInvoiceDate = computed(() => setToLocaleDateString(invoiceListData.value.to_date));
const { tableData, createBillingAccountUrl } = useTableData();

const isWideTable = ref(false);
const emit = defineEmits<{ (e: "isWideTable", value: boolean): void }>();

const tableHeaders = computed(() => {
  let headers = [
    { title: t("table.headers.paymentStatus"), type: "status-badge", isChecked: true },
    { title: t("table.headers.invoiceNumber"), type: "text", isChecked: true },
    { title: t("table.headers.billingAccountNumber"), type: "sspa-link", isChecked: true },
    { title: t("table.headers.invoiceDate"), type: "text", isChecked: true },
    { title: t("table.headers.invoiceDueDate"), type: "text", isChecked: true },
    { title: t("table.headers.totalAmount"), type: "text", isChecked: true },
    { title: t("table.headers.downloadPdf"), type: "button", isChecked: true },
  ];

  headers.push({
    title: t("table.headers.previewInvoice"),
    type: "button",
    isChecked: true,
  });

  if (isEnabled.isFeatureToggleDownloadXmlEnabled()) {
    headers.push({ title: "", type: "link", isChecked: true });
  }

  return headers;
});

const tableIsLoading = computed(() => {
  return layout.value.showLoading;
});

const missingInvoiceOnPageText = computed(() => {
  return isInvoicesMissingOnPage.value ? t("table.feedback.missingInvoices") : "";
});

const exportFormAction = computed(() => {
  const scopeId = invoiceListData.value.scopeId;
  const tscid = selectedOrganisation.value;
  return `/.api/corp-customer-invoices/v1/getInvoiceList/${scopeId}/invoices/export/${tscid}`;
});

const exportPaymentStatus = computed(() => {
  const maxTotalInvoicesWithPaymentStatus = 500;
  return invoiceListData.value.pagination.totalInvoices <= maxTotalInvoicesWithPaymentStatus;
});

const exportLineLabel = computed(() => {
  return t("table.export.csvFormat", {
    totalInvoices: invoiceListData.value.pagination.totalInvoices,
  });
});

const getTableRowsLimit = computed(() => {
  return tableData.value.length;
});

const getTableRowsCount = computed(() => {
  return invoiceListData.value.pagination.totalInvoices;
});

const handleTableButtonClicked = async (event) => {
  const rowNumber = event.detail.rowNumber;
  const buttonText = event.detail.buttonText;
  if (buttonText === getPreviewInvoiceButtonText()) {
    openInvoicePreviewDrawer(rowNumber);
  } else if (buttonText === getDownloadPdfButtonText()) {
    openDownloadPdfDrawer(
      invoiceListData.value.scopeId,
      selectedOrganisation.value,
      invoiceListData.value.invoicesList[rowNumber]
    );
  }
};

const getPreviewInvoiceButtonText = () => {
  return t("table.tableData.previewInvoiceButton");
};

const getDownloadPdfButtonText = () => {
  return t("table.tableData.download");
};

const exportData = async () => {
  trackExportTableDataGa(analytics.action.COMPLETED, exportPaymentStatus.value);
  await nextTick();
  exportFormData(
    exportFormAction.value,
    currentLanguage(),
    fromInvoiceDate.value,
    toInvoiceDate.value,
    invoiceListData.value.invoiceNr,
    invoiceListData.value.selectedSubscriptionId,
    invoiceListData.value.account.accountNr,
    exportPaymentStatus.value as unknown as string
  );
};

const exportPopoverOpened = () => {
  trackExportTableDataGa(analytics.action.INITIATED, exportPaymentStatus.value);
};

const openInvoicePreviewDrawer = async (rowNumber: number) => {
  const event = new CustomEvent("openPreviewInvoiceDrawer", {
    detail: {
      invoice: previewInvoiceDrawerProps(rowNumber),
    },
  });
  document.querySelector("body")?.dispatchEvent(event);
};

const previewInvoiceDrawerProps = (rowNumber: number) => {
  const invoice = invoiceListData.value.invoicesList[rowNumber];
  const billingAccountUrl = createBillingAccountUrl(
    invoiceListData.value.scopeId,
    selectedOrganisation.value,
    invoice.accountNumber
  );

  return {
    scopeId: invoiceListData.value.scopeId,
    tscid: selectedOrganisation.value,
    organizationNumber: selectedOrganisation.value,
    invoiceNumber: invoice.invoiceNumber,
    billingSystemID: invoice.billingSystemID,
    paymentStatus: invoice.paymentStatus,
    billingAccountUrl,
  };
};

const toggleIsWideTable = () => {
  isWideTable.value = !isWideTable.value;
  emit("isWideTable", isWideTable.value);
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.export-slot {
  display: flex;
  max-width: 26rem;
  column-gap: $telia-spacing-8;
  align-items: flex-start;

  .icon {
    padding-top: $telia-spacing-4;
  }
}

.export-form {
  visibility: hidden;
  display: none;
}
</style>
